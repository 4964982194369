<template>
  <div>
    <b-table
      v-if="serviceRequests.length"
      ref="refRelatedServiceRequestsTable"
      class="position-relative has_padding event_list min-height-200 shadow-table"
      :items="serviceRequests"
      responsive
      :fields="tableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: Role -->
      <template #cell(stringID)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'ServiceRequest')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
          >
            {{ data.item.stringID }}
            <br>
            <span class="font_normal">fr.</span>
            <b-button
              v-if="canViewThisAction('show', 'ServiceForm')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id"
              :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm._id } }"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="weight-400 align-middle detail-view-id not-button"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id not-button"
          >
            {{ data.item.stringID }}
            <span class="font_normal">fr.</span>
            <b-button
              v-if="canViewThisAction('show', 'ServiceForm')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id"
              :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm._id } }"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="weight-400 align-middle detail-view-id not-button"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
          </b-button>
        </div>
      </template>

      <template #cell(workFlow)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.workFlowName }}</span>
          <br>
          <span>File No: {{ data.item.fileNo || '-' }}</span>
        </div>
      </template>

      <template #cell(deceased)="data">
        <div class="text-nowrap">
          <span class="">Niche No: {{ data.item.serviceForm.deceasedNiche || '-' }}</span>
          <br>
          <span class="">Pedestal No: {{ data.item.serviceForm.deceasedPedestal || '-' }}</span>
          <br>
          <span class="">Anling Lot No.: {{ data.item.serviceForm.deceasedAngling || '-' }}</span>
        </div>
      </template>

      <template #cell(date)="data">
        <div class="text-nowrap">
          <span class="">{{ dateFormat(data.item.date) }},</span>
          <br>
          <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
        </div>
      </template>

      <template #cell(location)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.location }}</span>
          <br>
          <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
        </div>
      </template>

      <template #cell(serviceDescriptionValues)="data">
        <div
          v-if="data.item.showServiceDescription == true"
        >
          <div
            v-for="(opt, key) in data.item.serviceDescriptionValues"
            :key="key"
          >
            <div
              v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value || (opt.children && opt.children.length))"
            >
              <span v-if="opt.children && opt.children.length">
                <div
                  v-for="(child, indx) in opt.children"
                  :key="indx"
                >
                  <div
                    v-if="((Array.isArray(child.value) && child.value.length) || child.value)"
                  >
                    <span
                      v-if="Array.isArray(child.value)"
                    >
                      <span
                        v-for="(option, idx) in child.value"
                        :key="idx"
                      >
                        {{ option }}
                        <feather-icon
                          :id="'unique_'+data.item._id+key+idx"
                          icon="AlertCircleIcon"
                          class="ml-50 text-primary"
                          size="14"
                        />
                        <b-tooltip
                          placement="auto"
                          :target="'unique_'+data.item._id+key+idx"
                          :title="child.key"
                        />
                      </span>
                    </span>
                    <span v-else-if="child.value">
                      <span
                        v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                      >
                        {{ child.key }} <span class="green-text">{{ child.value }}</span>
                      </span>
                      <span v-else>{{ child.key }} {{ child.value }}</span>
                    </span>
                  </div>
                </div>
              </span>
              <span
                v-if="Array.isArray(opt.value)"
              >
                <span
                  v-for="(option, idx) in opt.value"
                  :key="idx"
                >
                  {{ option }}
                  <feather-icon
                    :id="'unique_'+data.item._id+key+idx"
                    icon="AlertCircleIcon"
                    class="ml-50 text-primary"
                    size="14"
                  />
                  <b-tooltip
                    placement="auto"
                    :target="'unique_'+data.item._id+key+idx"
                    :title="opt.key"
                  />
                </span>
              </span>
              <span v-else-if="opt.value">
                <span
                  v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                >
                  {{ opt.key }} <span class="green-text">{{ opt.value }}</span>
                </span>
                <span v-else>{{ opt.key }} {{ opt.value }}</span>
              </span>
            </div>
          </div>
        </div>
        <div
          v-else-if="data.item.serviceDescription && data.item.serviceDescription.length && !data.item.serviceDescription[0].id"
        >
          <div
            v-for="(opt, key) in data.item.serviceDescription"
            :key="key"
          >
            <div
              v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value)"
            >
              <span
                v-if="Array.isArray(opt.value)"
              >
                <span
                  v-for="(option, idx) in opt.value"
                  :key="idx"
                >
                  {{ option }}
                  <feather-icon
                    :id="'unique_'+data.item._id+key+idx"
                    icon="AlertCircleIcon"
                    class="ml-50 text-primary"
                    size="14"
                  />
                  <b-tooltip
                    placement="auto"
                    :target="'unique_'+data.item._id+key+idx"
                    :title="opt.key"
                  />
                </span>
              </span>
              <span v-else-if="opt.value && opt.value != 0">
                <span>{{ opt.key }}</span> <span class="green-text">{{ opt.value }}</span>
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template #cell(agentName)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.agentName || '-' }}</span>
          <br>
          <span class="">{{ data.item.agentCode || '-' }}</span>
          <br>
          <span
            v-if="data.item.agentContact"
            class=""
          >
            (65) {{ data.item.agentContact }}
          </span>
          <span
            v-else
            class=""
          >
            -
          </span>
        </div>
      </template>

      <template #cell(customerName)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.customerName }}</span>
          <br>
          <span
            v-if="data.item.customerContact"
            class=""
          >
            (65) {{ data.item.customerContact }}
          </span>
        </div>
      </template>

      <template #cell(assignees)="data">
        <div
          v-if="data.item.assignees.length"
          class="text-nowrap"
        >
          <span
            v-for="(assignee, key) in data.item.assignees"
            :key="key"
          >
            {{ assignee.name }}<span v-if="key < (data.item.assignees.length - 1)">, </span>
          </span>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template #cell(paymentStatus)="data">
        <div
          class="text-nowrap"
        >
          <b-badge
            :variant="`light-${resolveServiceRequestPaymentStatusVariant(data.item.paymentStatus)}`"
            class="text-capitalize"
          >
            {{ data.item.paymentStatus }}
          </b-badge>
          <!-- <br>
              <span
                class=""
              >
                {{ data.item.paymentRemarks || '-' }}
              </span> -->
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${resolveServiceRequestStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template
        v-if="canViewThisAction('show', 'ServiceRequest') || canViewThisAction('update', 'ServiceRequest')
          || canViewThisAction('update-detail', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')
          || canViewThisAction('update-status', 'ServiceRequest') || canViewThisAction('delete', 'ServiceRequest')"
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-if="canViewThisAction('show', 'ServiceRequest')"
            :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
          >
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="canViewThisAction('update', 'ServiceRequest')
              || canViewThisAction('update-detail', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')
              || canViewThisAction('update-status', 'ServiceRequest')"
            :to="{ name: 'operation-service-requests-edit', params: { id: data.item._id } }"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="canViewThisAction('delete', 'ServiceRequest')"
            @click="deleteEntity(data.item._id)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No service requests found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip, BBadge, BDropdown, BDropdownItem, BTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    serviceRequests: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'serviceForm',
          label: '',
          sortable: false,
          tdClass: 'service-form-group',
          variant: 'secondary',
        },
        {
          key: 'stringID',
          label: 'id',
          sortable: true,
        },
        {
          key: 'workFlow',
          label: 'Request Details',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Service Date',
          sortable: true,
        },
        {
          key: 'location',
          label: 'Location & Time',
          sortable: true,
        },
        {
          key: 'serviceDescriptionValues',
          label: 'Service descriptions',
          sortable: false,
          thStyle: { width: '310px', minWidth: '310px' },
        },
        // {
        //   key: 'remarks',
        //   label: 'remarks',
        //   sortable: false,
        // },
        {
          key: 'agentName',
          label: 'Agent',
          sortable: true,
        },
        // {
        //   key: 'customerName',
        //   label: 'Customer',
        //   sortable: true,
        // },
        // {
        //   key: 'assignees',
        //   label: 'Assigned To',
        //   sortable: true,
        // },
        { key: 'paymentStatus', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveServiceRequestPaymentStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Partially Paid') return 'warning'
      if (status === 'Unpaid') return 'danger'
      return 'secondary'
    },
    resolveServiceRequestStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'pending') return 'warning'
      if (status === 'awaiting approval') return 'danger'
      return 'primary'
    },
  },
}
</script>
